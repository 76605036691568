var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-page-toolbar"},[(_vm.record && _vm.hasPermissionToEdit)?_c('router-link',{attrs:{"to":{ path: ("/iam/" + (_vm.record.id) + "/edit") }}},[_c('el-button',{attrs:{"icon":"el-icon-fa-edit","type":"primary"}},[_c('app-i18n',{attrs:{"code":"common.edit"}})],1)],1):_vm._e(),(_vm.record && _vm.hasPermissionToEdit)?_c('el-button',{attrs:{"disabled":_vm.loading,"icon":_vm.record.disabled
        ? 'el-icon-fa-check'
        : 'el-icon-fa-ban',"type":"primary"},on:{"click":function($event){return _vm.doToggleStatus()}}},[(_vm.record.disabled)?_c('app-i18n',{attrs:{"code":"iam.enable"}}):_vm._e(),(!_vm.record.disabled)?_c('app-i18n',{attrs:{"code":"iam.disable"}}):_vm._e()],1):_vm._e(),(_vm.record && _vm.hasPermissionToAuditLogs)?_c('router-link',{attrs:{"to":{
      path: '/audit-logs',
      query: { entityId: _vm.record.id },
    }}},[_c('el-button',{attrs:{"icon":"el-icon-fa-history"}},[_c('app-i18n',{attrs:{"code":"auditLog.menu"}})],1)],1):_vm._e(),(_vm.record && _vm.hasPermissionToAuditLogs)?_c('router-link',{attrs:{"to":{
      path: '/audit-logs',
      query: { createdByEmail: _vm.record.email },
    }}},[_c('el-button',{attrs:{"icon":"el-icon-fa-eye"}},[_c('app-i18n',{attrs:{"code":"iam.view.activity"}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }